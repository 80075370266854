html,
body {
  height: 100%;
}
body {
  background-color: white;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.app-header {
  justify-content: space-between;
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.example-fill-remaining-space {
  flex: 1 1 auto;
}

:host /deep/ .mat-sidenav-fixed {
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0.9;
  width: 100%;
}

:host /deep/ .mat-nav-list .mat-list-item {
  display: flex;
  justify-content: center;
}

.nav-list-style {
  padding: 8px;
  text-decoration: none;
  font-size: x-large;
  color: #07658a0d;
  display: block;
  transition: 0.3s;
}

.positronx {
  text-decoration: none;
  color: #ffffff;
}

.example-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.example-is-mobile .example-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

h1.example-app-name {
  margin-left: 8px;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-is-mobile .example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

.active {
  /* background: #3f51b5;
    color: white; */
  /* background-color: #3f51b5 !important;
    color: white !important;
    text-decoration: none; */
  font-size: x-large;
  color: white !important;
}

:host /deep/ .mat-list-base {
  padding-top: 0px;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content .active {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  text-decoration: none;
}
hr.new4 {
  border: 1px solid white;
}

section {
  display: block;
  margin: 8px;
}

.example-button-row {
  display: table-cell;
}

.example-button-row button {
  display: table-cell;
  margin-right: 8px;
}

.example-flex-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.example-button-container {
  display: flex;
  justify-content: center;
  width: 120px;
}
.card {
  border: none;
  cursor: pointer;
}
.login,
.image {
  min-height: 100vh;
}

.bg-style {
  background-size: cover;
  background-color: #3f51b5;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.login,
.image {
  min-height: 100vh;
}

.bg-style {
  background-color: rgb(7, 105, 144);
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}
table {
  width: 100%;
}
.hide {
  display: none;
}
mat-footer-row mat-footer-cell {
  justify-content: center;
  font-style: italic;
}
.icon {
  outline: none;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
table {
  width: 100%;
}
.hide {
  display: none;
}
mat-footer-row mat-footer-cell {
  justify-content: center;
  font-style: italic;
}
.icon {
  outline: none;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

.nav-container {
  height: 100%;
}
.side-container {
  width: 15%;
  height: 100%;
  background-color: rgb(30, 115, 253);
}
.mat-list-item {
  color: white;
}
.content {
  margin: 20px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}
.card2 {
  background-color: rgb(6, 120, 139);
  color: white;
  width: 80%;
  margin-left: 10%;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.mat-stepper-vertical {
  margin-top: 8px;
}

hr {
  border-top: 1px dotted #000;
  /*Rest of stuff here*/
}
.imgbody {
  height: 100%;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 130px;
  min-height: 130px;
}

.btn:hover {
  background-color: rgb(8, 84, 114);
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.mat-stepper-vertical {
  margin-top: 8px;
}

hr {
  border-top: 1px dotted #000;
  /*Rest of stuff here*/
}
.imgbody {
  height: 100%;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 130px;
  min-height: 130px;
}

.btn:hover {
  background-color: rgb(8, 84, 114);
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-card {
  max-width: auto;
}

.userimage {
  background-image: url("https://designguru.s3.amazonaws.com/man+ico.svg");
  background-size: cover;
  background-color: cornsilk;
  width: 10vh;
  height: 10vh;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}
table {
  width: 100%;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.mat-stepper-vertical {
  margin-top: 8px;
}

hr {
  border-top: 1px dotted #000;
  /*Rest of stuff here*/
}
.imgbody {
  height: 100%;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 130px;
  min-height: 130px;
}

.btn:hover {
  background-color: rgb(8, 84, 114);
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
.mat-stepper-vertical {
  margin-top: 8px;
}

hr {
  border-top: 1px dotted #000;
  /*Rest of stuff here*/
}
.imgbody {
  height: 100%;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 130px;
  min-height: 130px;
}

.btn:hover {
  background-color: rgb(8, 84, 114);
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.example-form {
  min-width: 50px;
  max-width: 490px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.side {
  width: 50px;
  height: 50px;
  background-color: black;
}
#canvas {
  border: 1px dashed #cccccc;
}
.main-title {
  margin-top: 10px;
}
.images-item {
  width: 48%;
  cursor: pointer;
  -webkit-transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  padding: 3px;
  max-width: 120px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(180, 176, 176);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(45, 46, 49);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5b9b9;
}
.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.images-item:hover {
  opacity: 0.7;
}

.images-item-upload {
  width: 80%;
  cursor: pointer;
  padding-bottom: 10px;
  -webkit-transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
}

.images-item-upload:hover {
  opacity: 0.7;
}

.custom-item {
  border-bottom: 2px solid #f3f3f3;
}

.custom-item .custom-item-title {
  color: #666666;
  font-weight: 600;
}

.custom-item .custom-item-body {
  padding: 10px 0;
}

.max-height {
  overflow: auto;
  max-height: 300px;
}
.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}
.mat-expansion-panel {
  background: #332f2f;
}
.mat-expansion-panel-header-title {
  color: rgb(255, 255, 255);
  font-size: 15px;
}
.mat-expansion-panel-indicator,
.mat-expansion-panel-header-description /deep/ {
  color: rgb(255, 255, 255);
}
.element-style {
  color: rgb(255, 255, 255);
}
.input-group-text,
.form-control {
  font-size: 12px;
  padding-top: 0.375rem;
  padding-right: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Vollkorn";
}

h1 {
  font-size: 55px;
}

mat-card {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}
.counter {
  height: 150px;
  background-color: white;
  border: 20px;
  border-color: black;
}
* {
  margin: 0;
  padding: 0;
}
.slider-ctn {
  margin: auto;
  width: 200px;
}
.slider-ctn figure.logo {
  background: url("https://dummyimage.com/200x100/000/fff") top left no-repeat;
  height: 0;
  background-size: cover;
  padding-top: 50%;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
td {
  font-size: 15px;
}
th {
  font-size: 15px;
}
.example-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}
.card1:hover {
  background-color: rgb(6, 120, 139);
  color: white;
}

.cardmargin {
  margin-bottom: 2px;
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

html,
body,
.grid-container {
  height: 100%;
  margin: 0;
}

.grid-container {
  display: grid;

  gap: 0px 53px;
  grid-template-areas:
    "Div Div Div aside"
    "Div Div Div aside"
    "Div- Div- Div- aside";
}

.Div {
  grid-area: Div;
}

.Div- {
  grid-area: Div-;
}

.aside {
  grid-area: aside;
}

.form-control {
  width: 94% !important;
}

:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: rgb(84, 84, 156);
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.grid {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0 auto;
  max-width: 60em;
  padding: 0;
}
@media (min-width: 42em) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
}
.card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);
  }
  .radio::after {
    border: var(--radio-border-width) solid #fff;
    border-top: 0;
    border-left: 0;
    content: "";
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 0.375rem;
  }
  .radio:checked {
    background: var(--color-green);
    border-color: var(--color-green);
  }

  .card:hover .radio {
    border-color: var(--color-dark-gray);
  }
  .card:hover .radio:checked {
    border-color: var(--color-green);
  }
}
.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  border-color: var(--color-green);
}

.radio:focus ~ .plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled ~ .plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled ~ .plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled ~ .plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: var(--color-green);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
::placeholder {
  color: black !important;
  opacity: 1;
}

#lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
  border-radius: 50%;
}

.invoice {
  background: #fff;
  padding: 20px;
}

.invoice-company {
  font-size: 20px;
}

.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px;
}

.invoice-date,
.invoice-from,
.invoice-to {
  display: table-cell;
  width: 1%;
}

.invoice-from,
.invoice-to {
  padding-right: 20px;
}

.invoice-date .date,
.invoice-from strong,
.invoice-to strong {
  font-size: 16px;
  font-weight: 600;
}

.invoice-date {
  text-align: right;
  padding-left: 20px;
}

.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%;
}

.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle;
}

.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
}

.invoice-price small {
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.invoice-price .invoice-price-row {
  display: table;
  float: left;
}

.invoice-price .invoice-price-right {
  width: 25%;
  background: #2d353c;
  color: #fff;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 300;
}

.invoice-price .invoice-price-right small {
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}

.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px;
}

.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%;
}

.invoice > div:not(.invoice-footer) {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .desktopview {
    display: none !important ;
  }
}
@media (min-width: 600px) {
  .mobileview {
    display: none !important ;
  }
}

.progress {
  height: 7px !important;
}

.progress-bar {
  background-color: red !important;
}

.uploadbtn {
  background-color: rgb(67, 67, 158) !important;
  color: white !important;
}

.imgbody {
  width: 190px;
  height: 125px;
  border-radius: 20px;
}
