.loaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logoutButton {
  border: none;
  background: gray;
  box-shadow: 0px 0px 7px darkgrey;
  border-radius: 9px;
  color: #ffffff;
}
